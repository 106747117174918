// IMAGE PATH
export const ROAM_IMAGE_SVG = `./assets/svg`;
export const ROAM_IMAGE_SIDENAV = './assets/svg/sidenav';
export const ROAM_IMAGE_RESIDENT = './assets/resident';
export const ROAM_ICON = './assets/svg/roam';

// NGX-TOASTR
export type RoamPosition = 'center' | 'remove'

export const ROAM_TOAST_CONFIG = {
  positionClass: 'toast-top-center',
  timeOut: 2000,
  closeButton: true
}
export const ROAM_TOASTR: Record<RoamPosition, any> = {
  center: {
    toastClass: 'roam-toastr ngx-toastr',
    ...ROAM_TOAST_CONFIG
  },
  remove: {
    toastClass: 'app-toastr-remove roam-toastr ngx-toastr toast-remove',
    ...ROAM_TOAST_CONFIG
  }
}

// DEVICE RESOLUTION
export const DEVICE_MIN_RESOLUTION_MENU = 1180;
